export default [
  {
    header: "Menu",
  },
  {
    title: "Dashboard",
    icon: "HomeIcon",
    route: "dashboard-ecommerce",
  },
  {
    title: "Todo",
    route: "apps-todo",
    icon: "CheckSquareIcon",
  },
  {
    title: "Users",
    icon: "UserIcon",
    route: "apps-users-list",
  },
  {
    title: "Pricing",
    icon: "CreditCardIcon",
    route: "pages-pricing",
  },
];
